import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Canadian Cancer Statistic Dashboard"
          des="The Canadian Cancer Statistics Dashboard (CCSD) provides user-friendly and interactive visualizations including up-to-date, projected estimates of cancer incidence and mortality across population groups and geographic regions."
          src="/CancerStats.PNG"
          linkWebsite="https://cancerstats.ca"
        />
        <ProjectsCard
          title="Investucation (Calgary Hack 2024)"
          des="Investucation offers a friendly and inviting interface where users can set financial goals. Users then take a Risk Assessment Survey, which calculates a score to determine their investment risk tolerance — a crucial aspect of financial strategy planning."
          src="/Invest.PNG"
          linkWebsite="https://hackathon-u-calgary.vercel.app/"
          linkSource={"https://devpost.com/software/investucation/edit"}
        />
        <ProjectsCard
          title="Linear Regression Model"
          des="Linear regression model On exploring life expectancy with different predictors across different categories."
          src="/math.webp"
          linkSource="https://github.com/lybned/STAT-429-Individual-Project"
        />
        <ProjectsCard
          title="Logistic Regression Model"
          des="Predicting whether a country is developed or developing based on different factors across different categories using logistic regression model. "
          src="/math.webp"
          linkSource="https://github.com/lybned/STAT-429-Group-Project/tree/main"
        />
        <ProjectsCard
          title="Simple Weather App"
          des="A Weather App that shows the current and the next seven day forcast of a desired location."
          src="/WeatherApp.PNG"
          linkWebsite="https://weather-app-lemon-seven-73.vercel.app/"
          linkSource="https://github.com/lybned/Weather-App"
        />  
        <ProjectsCard
          title="Deep Learning on Lung Images"
          des="The main goal/objectives of our project is to create a model that learn through several processing layers that break down complex images into series of simple mappings so that it can learn the difference between the images feeded to it to discriminate into either malignant or benign kinds of cancer (specifically lung cancer)."
          src="/MachineLearning.jpg"
          linkSource="https://github.com/MohammadSoomro/CPSC_599_PROJECT/"
        />
        <ProjectsCard
          title="Course Enrollment App"
          des="This is the final project that I made with my group during CPSC 471 (DataBase Management systems). The final report includes the screenshots of the web interface, API endpoints, entity relational model and EERD."
          src="/Course.png"
          linkSource="https://docs.google.com/document/d/1QgO09YDzQ5Bq7i-kxOa7NqUyO_XSZkcCGBZkcf_3hn8/edit"
        />
      </div>
    </section>
  );
}

export default Projects