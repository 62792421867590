import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">Education</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full border-l-[6px] border-l-black border-opacity-30 gap-10">
          <ResumeCard
            title="BSc in Computer Science Honor with Data Science Minor"
            subTitle="University of Calgary (Sept 2019 - April 2024)"
            result="3.7/4"
            des={["GPA: 3.7/4.0", "Dean’s List 2019 - 2020 and 2020 - 2021"]}
          />
        </div>
      </div>
      {/* part Two */}

      <div>

      </div>
    </motion.div>
  );
};

export default Education;
