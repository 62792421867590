import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaReact } from "react-icons/fa";
import { SiDotnet, SiSqlite, SiBootstrap, SiTailwindcss   } from "react-icons/si";

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["Data Scientist.", "Full Stack Developer.", "Cybersecurity Enthusiast."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Yanbo Liu</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        Ned Liu here, concluding my fifth year in Computer Science at the University of Calgary. 
        With a broad interest spanning across Software Engineering, Information Security, and Data Science, I specialize in full-stack development, combining front-end innovation with back-end efficiency. 
        Proficient in technologies across the stack, including React, Node.js, and database systems.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            <a href="https://twitter.com/YanboLiu1240781"  target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <FaTwitter />
              </span>
            </a>

            <a href="https://www.linkedin.com/in/yanbo-liu-601622229/"  target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <FaLinkedinIn />
              </span>
            </a>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4 flex-wrap">
            <a href="https://react.dev/"  target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <FaReact />
              </span>              
            </a>

            <a href="https://dotnet.microsoft.com/en-us/apps/aspnet"  target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <SiDotnet />
              </span>              
            </a>
            
            <a href="https://www.sqlite.org/index.html"  target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <SiSqlite />
              </span>              
            </a>

            <a href="https://getbootstrap.com/"  target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <SiBootstrap />
              </span>              
            </a>

            <a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <SiTailwindcss  />
              </span>              
            </a>

          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner