import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div className="w-full">
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full  border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Research Assistant/Computer Science Intern"
            subTitle="Oncology Outcomes - (2022 May - Present)"
            result="Canada"
            des={["Enhanced user experience and functionality of a web-based application using Bootstrap 5, HTML/CSS, JavaScript, jQuery, and ASP.NET CORE",
            "Successfully developed and deployed a robust web-based application, accessible at https://cancerstats.ca/",
            "Implemented SQLite and SQL Server for database development and maintenance, ensuring efficient data management.",
            "Integrated Chart JS and D3 JS to create interactive data visualizations, making complex data more accessible and user-friendly.",
            "Employed AWS EC2 for reliable and scalable hosting of the website."]}
            imgSource="/O2-Logo.PNG"
            skills={["ASP.NET CORE", "C#", "HTML/CSS", "JavaScript", "JQuery", "SQLite", "Chart.js", "AWS"]}
            
          />
          <ResumeCard
            title="Information Security Club at the University of Calgary"
            subTitle="VP Tech - (2021 Sept - Present)"
            result="Canada"
            des={["Helped with weekly practice sessions and annual CTF. Hosted a practice session, and mentored members from the group.",
            "Participated in a cybersecurity competition named “CyberSci”. In 2023, our team got 1st place in the regional competition.",
            "Organizer of Magpie CTF 2023, made a total of 5 challenges for the CTF. The challenge Repository can be found here: https://github.com/infosec-ucalgary/magpieCTF-2023/tree/main",
            "Club Website: https://infosecucalgary.ca/"]}
            imgSource="/Infosec.png"
            skills={["Cybersecurity", "Nmap", "Kali Linux", "HackTheBox", "CTF (Capture the flag)", "Ghidra", "Dirb", "Wireshark"]}      
          />
        </div>
      </div>

    </motion.div>
  );
};

export default Experience;
